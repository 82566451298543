<template>
  <section class="mt-large heavily padded">
    <h1 class="text-centered">
      <translate translate-context="Documentation,GetStarted/*/*">
        What is Vmocall TSM?
      </translate>
    </h1>
    <div class="stackable preserve-print row text-centered">
      <div class="padded six wide">
        <img
          class="regular-width"
          :src="`${publicPath}illustrations/Analysis_Isometric.svg`"
          alt="Analysis illustration">
        <p class="large-text mt-regular">
          <translate translate-context="Documentation,GetStarted/*/*">
            Vmocall TSM is installed on your computer and supports you during your calls.
          </translate>
        </p>
      </div>
      <div class="padded six wide">
        <img
          class="regular-width"
          :src="`${publicPath}illustrations/Conversation_Isometric.svg`"
          alt="Conversation illustration">
        <p class="large-text mt-regular">
          <translate translate-context="Documentation,GetStarted/*/*">
            Vmocall TSM removes background noise and optimizes your voice quality.
          </translate>
        </p>
      </div>
    </div>
    <div class="stackable preserve-print row text-centered">
      <div class="padded six wide">
        <img
          class="regular-width"
          :src="`${publicPath}illustrations/Customer service_Monochromatic.svg`"
          alt="Customer service illustration">
        <p class="large-text mt-regular">
          <translate translate-context="Documentation,GetStarted/*/*">
            Vmocall TSM makes your conversations easier, to reduce your effort and increase your performance.
          </translate>
        </p>
      </div>
      <div class="padded six wide">
        <img
          class="regular-width"
          :src="`${publicPath}illustrations/Finger print_Two Color.svg`"
          alt="Fingerprint illustration">
        <p class="large-text mt-regular">
          <translate translate-context="Documentation,GetStarted/*/*">
            Vmocall TSM preserves your identity and intent.
          </translate>
        </p>
      </div>
    </div>
    <hr class="my-large">
    <div class="tiny container">
      <h1 class="text-centered">
        <translate translate-context="Documentation,GetStarted/*/*">
          Get started with Vmocall TSM
        </translate>
      </h1>
      <div class="stackable row v-centered preserve-print mt-large">
        <div class="nine wide">
          <h4>
            <translate translate-context="Documentation,GetStarted/*/*">
              Turn your computer on
            </translate>
          </h4>
          <p>
            <translate translate-context="Documentation,GetStarted/*/*">
              Vmocall TSM will start and you'll see an icon in the system tray.
            </translate>
          </p>
        </div>
        <div class="three wide">
          <img
            class="small-width"
            :src="`${publicPath}illustrations/Campaign launch_Isometric.svg`"
            alt="Launch illustration">
        </div>
      </div>
      <div class="stackable row v-centered preserve-print mt-large">
        <div class="nine wide">
          <h4>
            <translate translate-context="Documentation,GetStarted/*/*">
              Plug your microphone or headset in
            </translate>
          </h4>
          <p>
            <translate translate-context="Documentation,GetStarted/*/*">
              It will be recognized automatically and instantly by Vmocall TSM.
            </translate>
          </p>
        </div>
        <div class="three wide">
          <img
            class="small-width"
            :src="`${publicPath}illustrations/Podcast_Monochromatic.svg`"
            alt="Microphone illustration">
        </div>
      </div>
      <div class="stackable row v-centered preserve-print mt-large">
        <div class="nine wide">
          <h4>
            <translate translate-context="Documentation,GetStarted/*/*">
              Select "VoiceMeeter Input" as your microphone
            </translate>
          </h4>
          <p>
            <translate translate-context="Documentation,GetStarted/*/*">
              By doing so in your softphone/call application, your voice is enhanced in real time during all calls.
            </translate>
          </p>
        </div>
        <div class="three wide">
          <img
            class="small-width"
            :src="`${publicPath}illustrations/Click Here_Monochromatic.svg`"
            alt="Fingerprint illustration">
        </div>
      </div>
      <div class="stackable row v-centered preserve-print mt-large">
        <div class="nine wide">
          <h4>
            <translate translate-context="Documentation,GetStarted/*/*">
              Vmocall TSM is now supporting you to make the best of your calls.
            </translate>
          </h4>
        </div>
        <div class="three wide">
          <img
            class="small-width"
            :src="`${publicPath}illustrations/Checklist_Two Color.svg`"
            alt="Checklist illustration">
        </div>
      </div>
    </div>
    <hr class="my-large">
    <div class="tiny container">
      <h1 class="text-centered">
        <translate translate-context="Documentation,GetStarted/*/*">
          Common questions
        </translate>
      </h1>
      <h3>
        <translate translate-context="Documentation,GetStarted/*/*">
          How do I know Vmocall TSM is running?
        </translate>
      </h3>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          The Vmocall TSM icon in the system tray will let you know if everything is running correctly.
        </translate>
      </p>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          Additionally, if you click on the icon, a dedicated window will open and provide you with more information.
        </translate>
      </p>
      <h3 class="mt-large">
        <translate translate-context="Documentation,GetStarted/*/*">
          Does Vmocall TSM change my accent?
        </translate>
      </h3>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          To improve the quality of your conversations, Vmocall TSM focus on enhancing speech clarity, expressiveness, smile and removing background noises. 
        </translate>
      </p>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          By design and to ensure you sound natural, other features of your voice such as speed or accent are left untouched.
        </translate>
      </p>
      <h3 class="mt-large">
        <translate translate-context="Documentation,GetStarted/*/*">
          Does Vmocall TSM enhance my voice all the time?
        </translate>
      </h3>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          When Vmocall TSM is first deployed on your device, enhancements may be enabled and disabled remotely.
        </translate>
      </p>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          This is required to tailor our enhancements to your voice and to ensure that Vmocall TSM actually helps you on a daily basis.
        </translate>
      </p>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          Once this tuning period is over, Vmocall TSM will run continuously. Occasionally and for short time frames, it may be disabled remotely as part of our quality assessment process.
        </translate>
      </p>
      <h3 class="mt-large">
        <translate translate-context="Documentation,GetStarted/*/*">
          Does Vmocall TSM record me?
        </translate>
      </h3>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          When you use Vmocall TSM, and if you gave your consent for such recordings, Vmocall TSM may occasionally record some short samples of your voice (typically less than two minutes).
        </translate>
      </p>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          These recordings are processed to:
        </translate>
      </p>
      <ul>
        <li>
          <translate translate-context="Documentation,GetStarted/*/*">
            Fine-tune our enhancements to your voice and environment
          </translate>
        </li>
        <li>
          <translate translate-context="Documentation,GetStarted/*/*">
            Ensure Vmocall TSM continue to deliver the best results
          </translate>
        </li>
      </ul>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          Consent for automated recordings can be withdrawn at any moment from within Vmocall TSM's window. Audio samples are discarded after a maximum of two weeks.
        </translate>
      </p>
      <h3 class="mt-large">
        <translate translate-context="Documentation,GetStarted/*/*">
          What personal information does Vmocall TSM collect?
        </translate>
      </h3>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          Vmocall TSM needs some information to operate properly, such as your IP address, your username or data related to your computer and microphone.
        </translate>
      </p>
      <p>
        <translate translate-context="Documentation,GetStarted/*/*">
          When you use Vmocall TSM for the first time, we'll ask you for your consent before collecting anything. Afterwards, you can freely change or revoke your consent from within Vmocall TSM interface.
        </translate>
      </p>
      <p v-translate translate-context="Documentation,GetStarted/*/*">
        Collected data is handled in compliance with the GDPR regulations. For more information regarding our data processing, please visit our <a href="/privacy" class="internal">Privacy page</a>.
      </p>
    </div>
    <hr class="hidden">
  </section>
</template>

<script>

export default {
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>